<template>
  <div class="common-wrapper">
    <section class="filter-block">
      <div>
        <section>
          <a-select ref="select" v-model:value="filterForm.bizCategoryId" style="width: 140px" placeholder="请选择设备分类"
            @change="changeCategory">
            <a-select-option v-for="item in categoryList" :value="item.value" :key="item.value">{{ item.label
            }}</a-select-option>
          </a-select>
        </section>
        <section>
          <a-tree-select
            treeDefaultExpandAll
            v-model:value="filterForm.bizDeviceIds"
            style="width: 240px"
            :dropdownMatchSelectWidth="false"
            :dropdownStyle="{width:'360px'}"
            :listHeight="400"
            :tree-data="deviceList"
            tree-checkable
            :maxTagCount="1"
            allow-clear
            :fieldNames="{
              label:'name',
              value: 'id'
            }"
            placeholder="请选择设备"
          />
          <!-- <a-select style="width: 240px" allowClear :max-tag-count="1" mode="multiple" ref="select"
            v-model:value="filterForm.bizDeviceIds" placeholder="请选择设备">
            <a-select-option v-for="item in deviceList" :value="item.value" :key="item.value">{{ item.label
            }}</a-select-option>
          </a-select> -->
        </section>
        <section>
          <a-select allowClear ref="select" v-model:value="filterForm.staTimePeriod" @change="periodChange"
            style="width: 140px" placeholder="请选择统计周期">
            <a-select-option v-for="item in periodList" :value="item.value" :key="item.value">{{ item.label
            }}</a-select-option>
          </a-select>
        </section>
        <section>
          <a-range-picker v-model:value="rangeTime" style="width: 340px;" v-if="filterForm.staTimePeriod == 1"
            :disabled-date="disabledDate" @calendarChange="calendarChange" @change="dateChange" 
            :show-time="{
        hideDisabledOptions: true,
        defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')],
      }"
            />
          <a-range-picker v-model:value="rangeTime" v-else :picker="rangeType" @change="dateChange">
          </a-range-picker>
        </section>
        <section class="margin-section">
          <a-select allowClear :max-tag-count="1" mode="multiple" ref="select" v-model:value="filterForm.kpiCodes"
            style="width: 250px" placeholder="请选择维度和统计指标" @change="kpiChange">
            <a-select-option v-for="item in kpiList" :value="item.value" :key="item.value">{{ item.label
            }}</a-select-option>
          </a-select>
        </section>
      </div>
      <section class="action-btn">
        <a-button type="primary" @click="handleSearch">查询</a-button>
        <a-button @click="handleExport">导出</a-button>
      </section>
    </section>
    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange" :scroll="{ y: tableHeight }">
    </basic-table>
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'EquipmentReport'
// })
import { BasicTable } from '@/components/basic/table'
import { reactive, ref, onMounted, onBeforeUnmount, } from 'vue'
import { apiDeviceCategory, apiDeviceTree, apiDeviceKpiListExport, apiReportPeriod, apiDeviceKpi, apiDeviceKpiList } from "@/api/centerMonitor/deviceReport.js";
import dayjs from "dayjs";
import { message } from 'ant-design-vue';
import { downLoadFile } from "@/utils/excel";

const rangeTime = ref([])
const filterForm = reactive({
  bizDeviceIds: [],
  bizCategoryId: undefined,
  staTimePeriod: undefined,
  kpiCodes: [],
  begin: '',
  end: '',
})


const periodList = ref([])
const categoryList = ref([])
const deviceList = ref([])
const kpiList = ref([])
const initDeviceCategory = async () => {
  apiDeviceCategory().then((res) => {
    categoryList.value = res.result || []
  })
  apiReportPeriod().then((res) => {
    periodList.value = res.result || []
  })
  //console.log(123, apiDeviceList, apiReportPeriod)
}

const otherColumns = ref([])
const changeCategory = (val) => {
  console.log(12, val)
  apiDeviceTree({ bizCategoryId: val }).then(res => {
    deviceList.value = res.result?[res.result]:[]
    console.log(2323, deviceList.value)
  })
  // apiDeviceList({ bizCategoryId: val }).then(res => {
  //   deviceList.value = res.result || []
  // })
  
  apiDeviceKpi({ bizCategoryId: val }).then(res => {
    kpiList.value = res.result || []

    otherColumns.value = res.result.map(item => ({
      title: !item.unit ? item.label : (`${item.label}(${item.unit})`),
      type: "text",
      key: item.property,
    }))
  })
  filterForm.bizDeviceIds = []
  filterForm.kpiCodes = []
  deviceList.value = []
  kpiList.value = []
}

const kpiChange = (val) => {
  let result = []
  val.forEach(item => {
    let findOne = kpiList.value.find(kpi => kpi.value == item)
    if (findOne) {
      result.push(findOne)
    }
  })
  result = result.length > 0 ? result : kpiList.value
  otherColumns.value = result.map(item => ({
    title: !item.unit ? item.label : (`${item.label}(${item.unit})`),
    type: "text",
    key: item.property,
  }))
}

const dateChange = (time) => {
  if (filterForm.staTimePeriod == 1) {
    filterForm.begin = dayjs(time[0].$d).format("YYYY-MM-DD HH:mm:ss");
    filterForm.end = dayjs(time[1].$d).format("YYYY-MM-DD HH:mm:ss");
  } else if (filterForm.staTimePeriod == 2) {
    filterForm.begin = dayjs(time[0].$d).format("YYYY-MM-DD");
    filterForm.end = dayjs(time[1].$d).format("YYYY-MM-DD");
  } else if (filterForm.staTimePeriod == 3) {
    filterForm.begin = dayjs(time[0].$d).format("YYYY-MM");
    filterForm.end = dayjs(time[1].$d).format("YYYY-MM");
  } else if (filterForm.staTimePeriod == 4) {
    filterForm.begin = dayjs(time[0].$d).format("YYYY");
    filterForm.end = dayjs(time[1].$d).format("YYYY");
  }

  console.log(123, time, filterForm)
}


const rangeType = ref('day')
const periodChange = (val) => {
  if (val == 2) {
    rangeType.value = 'day'
  } else if (val == 3) {
    rangeType.value = 'month'
  } else if (val == 4) {
    rangeType.value = 'year'
  }
  filterForm.begin = ''
  filterForm.end = ''
  rangeTime.value = []
}


const dates = ref(null)
const calendarChange = (val) => {
  dates.value = val;
  console.log(12, dates.value)
}

const disabledDate = (current) => {
  if (!dates.value || dates.value.length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'year') >= 1;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'year') >= 1;
  return tooLate || tooEarly;
};


const baseColumns = [
  {
    title: "项目名称",
    type: "text",
    key: "projectName",
  },
  {
    title: "设备名称",
    type: "text",
    key: "deviceName",
  },
  {
    title: "设备编码",
    type: "text",
    key: "deviceCode",
  },
  {
    title: "统计时间",
    type: "text",
    key: "staTime",
  }
]
const tableConfig = reactive({
  pagination: true, //分页
  isPageBottom: true, //分页在底部
  remote: true,  //!!!!!!!!! 自动加载
  remoteApi: apiDeviceKpiList,//!!!!!!!!!!自动加载
  immediate: false, //立即加载数据
  rowKey: "id",
  queryParams: {}, //过滤参数
  columns: [...baseColumns]
})

const tableHeight = ref(500)
const changeTableHeight = () => {
  if (document.body.clientWidth > 1600) {
    tableHeight.value = document.body.clientHeight - 278
  } else {
    tableHeight.value = document.body.clientHeight - 320
  }
}

onMounted(() => {
  changeTableHeight()
  tableRef.value.paginationProps.pageSize = 20
  window.addEventListener('resize', changeTableHeight)
  initDeviceCategory()
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', changeTableHeight)
})
//自动加载数据
const tableRef = ref();

const handleSearch = () => {

  tableConfig.columns = [...baseColumns, ...otherColumns.value]
  tableConfig.queryParams = filterForm
  console.log(232323, tableConfig.columns)
  if (filterForm.staTimePeriod && !filterForm.begin && !filterForm.end) {
    message.warning('请选择时间')
    return;
  }
  if (!filterForm.staTimePeriod) {
    message.warning('请选择统计周期')
    return;
  }
  console.log(123, tableConfig.queryParams)
  tableRef.value.reload()
}

const handleExport = async () => {
  let res = await apiDeviceKpiListExport(filterForm);
  const { filename, blob } = res
  downLoadFile(blob, decodeURI(filename));
  message.success("导出成功");
}

</script>
<style lang='less' scoped>
.filter-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: flex-end;

  &>div {
    display: flex;

    section {
      margin-right: 16px;
    }
  }


  .action-btn {
    button {
      margin-right: 10px;
    }

    margin-right: 0;
  }
}

@media (max-width: 1600px) {
  .filter-block>div {
    flex-wrap: wrap;
    width: 950px;
  }

  .margin-section {
    margin-top: 12px;
  }
}
</style>


import http from '@/utils/http'

/*
 * 设备报表-----设备类型
 * @param {*} params 
 * @returns 
 */
export function apiDeviceCategory(params){
    return http.request({
        url: '/cemp-energy/sta/getDeviceCategory',
        method: 'get',
        params
    })
}

/*
 * 设备报表-----设备list
 * @param {*} params 
 * @returns 
 */
export function apiDeviceList(params){
    return http.request({
        url: '/cemp-energy/sta/getDeviceByCategory',
        method: 'get',
        params
    })
}
/*
 * 设备报表-----设备tree
 * @param {*} params 
 * @returns 
 */
export function apiDeviceTree(params){
    return http.request({
        url: '/cemp-monitor/device-monitor/node-space-device-tree',
        method: 'get',
        params
    })
}

/*
 * 设备报表-----统计周期
 * @param {*} params 
 * @returns 
 */
export function apiReportPeriod(params){
    return http.request({
        url: '/cemp-energy/sta/getStaTimePeriod',
        method: 'get',
        params
    })
}

/*
 * 设备报表-----维度和指标
 * @param {*} params 
 * @returns 
 */
export function apiDeviceKpi(params){
    return http.request({
        url: '/cemp-energy/sta/device/kpi',
        method: 'get',
        params
    })
}

/*
 * 设备报表-----分页设备指标
 * @param {*} params 
 * @returns 
 */
export function apiDeviceKpiList(data){
    return http.request({
        url: '/cemp-energy/sta/device/list/data',
        method: 'post',
        data
    })
}

/*
 * 设备报表-----分页设备指标
 * @param {*} params 
 * @returns 
 */
export function apiDeviceKpiListExport(data){
    return http.request({
        url: '/cemp-energy/sta/device/list/data/export',
        method: 'post',
        responseType: "blob",
        data
    })
}